import Keycloak from 'keycloak-js';
const keycloakConfig = {
    url: 'https://stg.accounts.cyberid.vn/auth',
    realm: 'cyberid',
    clientId: 'Billgate'
}

const keycloak = new Keycloak(keycloakConfig);

export default keycloak
