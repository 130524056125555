import "../../index.css";
import { Card } from 'primereact/card';
import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import billService from "../../service/BillService";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import moment from "moment";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import ShowNotificationBill from "./ShowNotificationBill";
import { Calendar } from 'primereact/calendar';


function ListBill() {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [bills, setBills] = useState([]);
  const [billDetails, setBillDetails] = useState([]);
  const [first, setFirst] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [paginate, setPaginate] = useState({
    page: 0,
    size: 10,
  });
  var date = new Date();
  var dateBefore = date.setDate(date.getDate() - 2);
  console.log(date);
  console.log(dateBefore);
  const objectSearch = {
    maThongDiep: "",
    maLoaiThongDiep: "",
    soHoaDon: -1,
    maSoThue: "",
    tuNgay:new Date(dateBefore),
    denNgay: new Date(),
    kyHieu: "",
}
  const [search, setSearch] = useState(objectSearch);
  

  useEffect(() => {
    getAllService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginate]);

  const handleDataForm = (key, value) => {
    console.log(value);
    if(key ==='soHoaDon'){
      let number = -1;
      if(value.trim() !== ''){
         number = parseInt(value);
       
       
      }else{
        number = -1;
      }
      let newData = { ...search };
      newData[key] = number;
      setSearch(newData);
    }else{
      let newData = { ...search };
      newData[key] = value;
      setSearch(newData);
    }
    
}
  const getAllService = async () => {
    showLoader();
    const result = await billService.getAllBill(paginate.page,paginate.size,search);
    if (result && result.status === 1000) {
      setBills(result.data);
      setTotalRecord(result.count);
    }
    hideLoader();
  };

  
  const onHideDialog = () => {
    setVisibleDialog(false);
  };

  // const onHandleRefresh = () => {
  //   setFirst(1);
  //   setSearch({
  //     search: "",
  //   });

  //   setPaginate({
  //     page: 0,
  //     size: 10,
  //   });
  // };

  const onHandleSearchClick = async () => {
    // setFirst(0);
    // showLoader();
    // const dataBody = { ...paginate, ...search };
    // const result = await billService.getAllBill(dataBody);
    // if (result && result.status === 1000) {
    //   setBills(result.data);
    //   setTotalRecord(result.count);
    // }
    // hideLoader();
    console.log(search)
    getAllService();
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setPaginate({ ...paginate, size: event.rows, page: event.page });
  };

  const onShowClick = async (rowData) => {
    setVisibleDialog(true);
    showLoader();
    let arr = [];
    const result = await billService.getAllBillByCode(rowData.manhomgiaodich);
    if (result && result.status === 1000) {
      arr = result.data.map((item, index) => ({
        ...item,
        stt: index + 2,
      }));
      setBillDetails(arr);
    }
    hideLoader();
  };

  const renderRowIndex = (rowData, column) => {
    return column.rowIndex + 1 + first;
  };
  const renderTacVu = (data) => {
    return (
      <div>
        <i
          className="pi pi-envelope"
          style={{
            color: "blue",
            cursor: "pointer",
            textAlign: "center",
            paddingRight: "10px",
          }}
          onClick={() => onShowClick(data)}
          title={"Chi tiết"}
        />
      </div>
    );
  };
  const renderCreateDate = (rowData) => {
    return (
      <>
        {rowData.createdate !== null
          ? moment(rowData.createdate).format("DD/MM/YYYY HH:mm:ss")
          : ""}
      </>
    );
  };

  const leftContents = () => (
    <>
      <div>
        {/* <InputText
        className={"p-mr-5"}
        value={search.search}
        onChange={onHandleChangeSearch}
        tooltip={"Nhập mã thông diệp"}
        name={"search"}
        placeholder={"Nhập mã thông diệp"}
      /> */}
        <div className="p-grid">
          <div className="p-field p-col-3">
            <span className="p-float-label">
              <InputText id="inputtext" style={{ width: "100%" }} 
              onChange={(e) => handleDataForm('maThongDiep', e.target.value)}
              />
              <label htmlFor="inputtext">Mã thông diệp</label>
            </span>
          </div>
          <div className="p-field p-col-3">
            <span className="p-float-label" >
              <InputText id="inputtext" style={{ width: "100%" }} 
              onChange={(e) => handleDataForm('maLoaiThongDiep', e.target.value)}
              />
              <label htmlFor="inputtext">Mã loại thông diệp</label>
            </span>
          </div>
          <div className="p-field p-col-3">
            <span className="p-float-label" >
              <InputText id="inputtext" style={{ width: "100%" }}
              onChange={(e) => handleDataForm('soHoaDon', e.target.value)}
              />
              <label htmlFor="inputtext">Số hóa đơn</label>
            </span>
          </div>
          <div className="p-field p-col-3">
            <span className="p-float-label" >
              <InputText id="inputtext" style={{ width: "100%" }} 
              onChange={(e) => handleDataForm('maSoThue', e.target.value)}
              />
              <label htmlFor="inputtext">Mã số thuế</label>
            </span>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-field p-col-3">
            <span className="p-float-label">
              <InputText id="inputtext" style={{ width: "100%" }} 
              onChange={(e) => handleDataForm('kyHieu', e.target.value)}
              />
              <label htmlFor="inputtext">Ký hiệu</label>
            </span>
          </div>
          <div className="p-field p-col-3">
            <span className="p-float-label">
              <Calendar value={search.tuNgay} id="calendar" style={{ width: "100%" }} 
              onChange={(e) => handleDataForm('tuNgay', e.value)}
              />
              <label htmlFor="calendar">Từ ngày</label>
            </span>
          </div>
          <div className="p-field p-col-3">
            <span className="p-float-label">
              <Calendar value={search.denNgay} id="calendar" style={{ width: "100%" }} 
              onChange={(e) => handleDataForm('denNgay', e.value)}
              />
              <label htmlFor="calendar">Đến ngày</label>
            </span>
          </div>
          <div className="p-field p-col-3">
            <span className="p-float-label" >
            <Button label="Tìm kiếm" icon="pi pi-search" 
            onClick={onHandleSearchClick}
            />
            </span>
          </div>
        </div>
      </div>

    </>
  );

  

  return (
    <div className="card">
      <Card>
        <div className="p-grid">
          <div className="p-field p-col-2"></div>
          <div className="p-field p-col-8">{leftContents()}</div>
          <div className="p-field p-col-2"></div>
        </div>
      </Card>

      <DataTable value={bills} emptyMessage="Không có dữ liệu">
        <Column
          body={renderRowIndex}
          header="STT"
          headerStyle={{ width: "4rem" }}
        />
        <Column field={"maloaithongdiep"} header="Mã loại thông điệp" />
        <Column field={"mathongdiep"} header="Mã thông điệp" />
        <Column
          field={"pban"}
          header="Phiên bản"
          className="p-text-nowrap p-text-truncate"
        />

        <Column field={"mngui"} header="Mã người gửi" />
        <Column field={"mnnhan"} header="Mã người nhận" />
        <Column
          field={"createdDate"}
          header="Ngày gửi"
          body={renderCreateDate}
        />
        <Column
          field={"tenhoso"}
          header="Tác vụ"
          className="p-text-center"
          body={renderTacVu}
        />
      </DataTable>
      <ShowNotificationBill
        visiable={visibleDialog}
        onHideDialog={onHideDialog}
        billDetails={billDetails}
      />

      <div className="p-d-flex p-mt-2">
        <div className="p-mt-3">
          <span>
            Tổng số <b>{totalRecord}</b> bản ghi
          </span>
        </div>
        <div className="p-ml-auto">
          <Paginator
            first={first}
            rows={paginate.size}
            totalRecords={totalRecord}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageChange={(event) => onPageChange(event)}
            template=" RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          />
        </div>
      </div>
      {loader}
    </div>
  );
}

export default ListBill;
