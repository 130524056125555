import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Column } from "primereact/column";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { Paginator } from "primereact/paginator";
import moment from "moment";
import { Button } from "primereact/button";
import PopupCreateNewBusiness from "./PopupCreateNewBusiness";
import businessManagementService from "../../../service/BusinessManagementService";
import Notification from "../../../hooks/Notification";
import { Tag } from "primereact/tag";
import PopupConfirm from "../../../component/common/PopupConfirm";
import PopupCreateNewUsers from "../UserManagement/PopupCreateNewUsers";

const BusinessManagement = () => {
  const notification = new Notification();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [idDelete, setIdDelete] = useState(0);
  const [statusUnLock, setStatusUnLock] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [first, setFirst] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [businessList, setBusinessList] = useState([]);
  const [typeAd, setTypeAd] = useState("1");
  const [typeAduser, setTypeAduser] = useState("1");
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [dataBusiness, setDataBusiness] = useState({
    diaChi: "",
    moTa: "",
    mst: "",
    soDienThoai: "",
    tenDoanhNghiep: "",
    tenNguoiDaiDien: "",
    topicIn: "",
    thuDienTu: "",
    topicOut: "",
    topicUsername: "",
    topicPassword: "",
    phuongThucGui:"",
  });



  const [visibleDialoguser, setVisibleDialoguser] = useState(false);
  const [dataUser, setDataUser] = useState({
    tenDangNhap: "",
    matKhau: "",
    thuDienTu: "",
    soDienThoai: "",
    mst: "",
    hoTen: "",
    trangThai: 0,
    hoatDong: 0,
    moTa: "",
  });

  const [paginate, setPaginate] = useState({
    page: 0,
    size: 10,
  });
  const [search, setSearch] = useState({
    search: "",
  });
  useEffect(() => {
    getAllService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, paginate]);
  const getAllService = async () => {
    showLoader();
    try {
      const dataBody = { ...paginate, ...search };
      const result = await businessManagementService.getAllBusinessManagement(
        dataBody
      );
      console.log(result.data);
      if (result && result.status === 1000) {
        setBusinessList(result.data);
        setTotalRecord(result.count);
      } else {
        let message = result.message;
        notification.notifyError(message);
      }
    } catch (e) {
      notification.notifyError("Lỗi kết nối");
    }
    hideLoader();
  };

  const onHandleChangeSearch = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };
  const onHandleSearchClick = async () => {
    setFirst(0);
    showLoader();
    hideLoader();
  };
  const onHandleRefresh = () => {
    setFirst(1);
    setSearch({
      search: "",
    });

    setPaginate({
      page: 0,
      size: 10,
    });
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setPaginate({ ...paginate, size: event.rows, page: event.page });
  };

  const onHideDialog = () => {
    setVisibleDialog(false);
    setDataBusiness({
      diaChi: "",
      moTa: "",
      mst: "",
      soDienThoai: "",
      tenDoanhNghiep: "",
      tenNguoiDaiDien: "",
      thuDienTu: "",
    });
  };
  const onHideDialoguser = () => {
    setVisibleDialoguser(false);
    setDataUser({
      tenDangNhap: "",
    matKhau: "",
    thuDienTu: "",
    soDienThoai: "",
    mst: "",
    hoTen: "",
    trangThai: 0,
    hoatDong: 0,
    moTa: "",
    });
  };
  const handleCreateNewBusiness = () => {
    setTypeAd("1");
    setVisibleDialog(true);
  };

  const handleEditBusiness = (data) => {
    setTypeAd("2");
    setDataBusiness(data);
    setVisibleDialog(true);
  };
  const handleShowData = (data) => {
    console.log('data: ', data);
    setTypeAd("3");
    console.log(data);
    setDataBusiness(data);
    setVisibleDialog(true);
  };

  const handleDeleteBusiness = async () => {
    setConfirm(false)
    const result = await businessManagementService.deleteBusinessManagement(
      idDelete
    );
    if (result && result.status === 1000) {
      setTimeout(getAllService, 500); // đợi 0.5s sau mới gọi hàm fetData()
      let message = result.message;
      notification.notifySuccess(message);
    } else {
      let message = result.message;
      notification.notifyError(message);
    }
  };

  const renderRowIndex = (rowData, column) => {
    return column.rowIndex + 1 + first;
  };
  const handleCreateNewuser = (data) => {
    setTypeAduser("1");
    setVisibleDialoguser(true);
    setDataUser({
      ...dataUser,
      mst: data.mst,
    });
  };
  const handleConfirm = (data) => {
    setConfirm(true);
    setIdDelete(data.id);
    setStatusUnLock(data.hoatDong);
  };
  const renderTacVu = (data) => {
    return (
      <div style={{textAlign:'center'}}>
        <i
          className="pi pi-eye "
          style={{ color: "blue", cursor: "pointer", textAlign: "center" }}
          onClick={() => handleShowData(data)}
          title={"Xem chi tiết"}
        />
        &ensp;
        <i
          className="pi pi-user-edit p-button-success"
          style={{ color: "#28a745", cursor: "pointer", textAlign: "center" }}
          onClick={() => handleEditBusiness(data)}
          title={"Chỉnh sửa"}
        />{" "}
        &ensp;
        <i
          className={data.hoatDong === 0 ? "pi pi-lock" : "pi pi-unlock"}
          style={{ color: "red", cursor: "pointer", textAlign: "center" }}
          onClick={() => handleConfirm(data)}
          title={data.hoatDong === 0 ? "Khóa" : "Mở khóa"}
        />
         &ensp;
        <i
          className={"pi pi-users"}
          style={{ color: "red", cursor: "pointer", textAlign: "center" }}
          onClick={() => handleCreateNewuser(data)}
          title={"Thêm người dùng"}
        />
      </div>
    );
  };

  const renderCreateDate = (rowData) => {
    return (
      <>
        {rowData.createdDate !== null
          ? moment(rowData.createdDate).format("DD/MM/YYYY HH:mm:ss")
          : ""}
      </>
    );
  };

  const renderRowStatus = (rowData) => {
    const status = rowData.trangThai;
    if (status === 0) {
      return <Tag severity="info" value={"Đang sử dụng"} />;
    } else if (status === 1) {
      return <Tag severity="warning" value={"Đã cập nhật"} />;
    }
  };

  const renderRowActive = (rowData) => {
    const status =
      rowData.hoatDong === 0 ? (
        <Tag severity="success" value="Hoạt động" />
      ) : (
        <Tag severity="danger" value="Khoá" />
      );
    return status;
  };

  const leftContents = (
    <React.Fragment>
      <InputText
        className={"p-mr-3"}
        value={search.search}
        onChange={onHandleChangeSearch}
        tooltip={"Nhập thông tin tìm kiếm"}
        name={"search"}
        placeholder={"Nhập thông tin tìm kiếm"}
      />
      &ensp;
      <Button
        icon="pi pi-search"
        title="Tìm kiếm"
        className="p-mr-2"
        onClick={onHandleSearchClick}
      />
      &ensp;
      <Button
        icon="pi pi-refresh"
        title="Làm mới"
        className="p-mr-2 p-button-help"
        onClick={onHandleRefresh}
      />
    </React.Fragment>
  );

  const rightContents = (
    <React.Fragment>
      <Button
        label="Thêm mới"
        icon="pi pi-plus"
        className="p-mr-2"
        onClick={handleCreateNewBusiness}
      />
    </React.Fragment>
  );
  return (
    <div className="card">
      <div className={"card-header"}>
        <Toolbar left={leftContents} right={rightContents} />
      </div>
      <DataTable value={businessList} emptyMessage="Không có dữ liệu">
        <Column
          body={renderRowIndex}
          header="STT"
          headerStyle={{ width: "4rem" }}
        />
        <Column field={"mst"} header="Mã số thuế" />
        <Column field={"tenDoanhNghiep"} header="Tên doanh nghiệp" />
        <Column
          field={"tenNguoiDaiDien"}
          header="Người đại diện"
          className="p-text-nowrap p-text-truncate"
        />

        <Column field={"thuDienTu"} header="Thư điện tử" />
        <Column field={"soDienThoai"} header="Số điện thoại" />
        <Column
          field={"createdDate"}
          header="Ngày gửi"
          body={renderCreateDate}
        />
        <Column
          field="trangThai"
          header="Trang thái"
          body={renderRowStatus}
          sortable
        />
        <Column
          field="hoatDong"
          header="Hoạt động"
          body={renderRowActive}
          sortable
        />
        <Column
          field={"tenhoso"}
          header="Tác vụ"
          className="p-text-center"
          body={renderTacVu}
        />
      </DataTable>

      <div className="p-d-flex p-mt-2">
        <div className="p-mt-3">
          <span>
            Tổng số <b>{totalRecord}</b> bản ghi
          </span>
        </div>
        <div className="p-ml-auto">
          <Paginator
            first={first}
            rows={paginate.size}
            totalRecords={totalRecord}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageChange={(event) => onPageChange(event)}
            template=" RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          />
        </div>
      </div>
      <PopupCreateNewBusiness
        visible={visibleDialog}
        onHideDialog={onHideDialog}
        typeAd={typeAd}
        dataBusiness={dataBusiness}
        setDataBusiness={setDataBusiness}
        fetDataBusiness={getAllService}
      />
      <PopupCreateNewUsers
        visible={visibleDialoguser}
        onHideDialog={onHideDialoguser}
        typeAd={typeAduser}
        dataUser={dataUser}
        setDataUser={setDataUser}
      />
      <PopupConfirm
        messageHeader="Thông báo !"
        messagaeBody={
          statusUnLock === 0
            ? "Bạn có chắc muốn khóa dữ liệu này !"
            : "Ban có chắc muốn mở khóa dữ liệu này"
        }
        visible={confirm}
        handleOk={handleDeleteBusiness}
        onHideDialog={() => {
          setConfirm(false);
        }}
      />
      {loader}
    </div>
  );
};

export default BusinessManagement;
