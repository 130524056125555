import axiosClient from "./AxiosClient";

const businessManagementService = {
  getAllBusinessManagement: (param) => {
    const url = `/bill-gate/doanh-nghiep/find-page?page=${param.page}&size=${param.size}&sort=createdDate,DESC&search=${param.search}`;
    return axiosClient
      .get(url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {});
  },
  getBusinessManagementByLogin: () => {
    const url = `/bill-gate/doanh-nghiep/lay-thong-tin-doanh-nghiep-voi-nguoi-dung-dang-nhap`;
    return axiosClient
      .get(url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {});
  },
  saveBusinessManagement: (data) => {
    const url = `/bill-gate/doanh-nghiep/them`;
    return axiosClient
      .post(url, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },

  editBusinessManagement: (data) => {
    const url = `/bill-gate/doanh-nghiep/sua`;
    return axiosClient
      .post(url, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },

  deleteBusinessManagement: (id) => {
    const url = `/bill-gate/doanh-nghiep/xoa?id=${id}`;
    return axiosClient
      .post(url, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};
export default businessManagementService;
