import { Button } from "primereact/button";
import React, { useState, useRef } from "react";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import sendXmlService from "../../service/SendXmlApi";
import { FileUploadCom } from "./fileupload";
import { Dropdown } from 'primereact/dropdown';
import "./index.css";

function SentXml() {
  // const notification = new Notification();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [base64excel, setBase64excel] = useState('');
  const [loaiTd, setloaiTd] = useState(0);
  const refFile = useRef(null)


  const sendXML = async () => {
    try {
      showLoader();
      console.log(loaiTd);
      let baseexcel = base64excel;
      let loaitd = loaiTd;
      let data = { baseexcel,loaitd };
      let res = await sendXmlService.sendxmltobill(data);
      console.log(res);
      hideLoader();
      refFile.current.clear();
    } catch (error) {
      console.log('errr', error);
      refFile.current.clear();

    }

  };
  const loaiTdSelectItems = [
    {label: 'Mã thông diệp của bill', value: 0},
    {label: 'Mã thông diệp của gate', value: 1}
];

  return (
    <div className="sentXML">
      <div className="card">
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-6">
            <FileUploadCom setBase64excel={setBase64excel}
              refFile={refFile}
            />
          </div>
          <div className="p-field p-col-6">
          <Dropdown value={loaiTd} options={loaiTdSelectItems} onChange={(e) => setloaiTd(e.value)} placeholder="Chọn loại mã thông diệp"/>
          </div>
        </div>
        <div className='button-xml'>
          <Button
            label="Gửi"
            className="p-button-success"
            onClick={sendXML}
          />
        </div>
        {loader}
      </div>
    </div>
  );
}

export default SentXml;
