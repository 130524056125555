import React, { useEffect, useState } from "react";
import { Menubar } from "primereact/menubar";
import { useHistory } from "react-router";
import { useKeycloak } from "@react-keycloak/web";
import imageLogo from "../../../src/static/images/logoBillgate.png";
import userManagementService from "../../service/UserManagementService";
import Notification from "../../hooks/Notification";
function MenuBar() {
  let history = useHistory();
  const { keycloak } = useKeycloak();
  const [active, setActive] = useState(false);
  const [admin, setAdmin] = useState(false);
  
  const onMouseOver = () => {
    setActive(true);
  };

  useEffect(() => {
    try {
      handleCheckRole()
      
    } catch (e) {
      Notification.notifyError("Lỗi kết nối");
    }
  }, [])

  const handleCheckRole = async() => {
    const result = await userManagementService.checkRoleAdmin();
    if (result && result.status === 1000) {
      setAdmin(result.data)
    } else {
      setAdmin(false);
    }
  }

  function handleLogout() {
    sessionStorage.setItem("permission", []);
    history.push("/");
    return keycloak.logout();
  }

  const itemsAdmin = [
    {
      label: "Lịch sử giao dịch",
      icon: "pi pi-fw pi-folder-open",
      command: () => history.push("/lich-su-giao-dich"),
    },
    {
      label: "Công cụ hỗ trợ",
      icon: "pi pi-fw pi-cog",
      items: [
        {
          label: "Gửi thông điệp/XML",
          icon: "pi pi-fw pi-inbox",
          command: () => history.push("/gui-hoa-don"),
        },
        {
          label: "Gửi lại thông diệp TCT",
          icon: "pi pi-fw pi-inbox",
          command: () => history.push("/gui-td-tct"),
        },
        {
          label: "Kiểm tra thông diệp",
          icon: "pi pi-fw pi-inbox",
          command: () => history.push("/check-thongdiep-bill"),
        },
      ],
    },
    
    
    {
      label: "Quản lý nhà cung cấp hóa đơn",
      icon: "pi pi-fw pi-th-large",
      items: [
        {
          label: "Quản lý người dùng",
          icon: "pi pi-fw pi-user",
          command: () => history.push("/nguoi-dung"),
        },
        {
          label: "Quản lý doanh nghiệp",
          icon: "pi pi-fw pi-users",
          command: () => history.push("/doanh-nghiep"),
        },
      ],
    },
    {
      label: "Cấu hình",
      icon: "pi pi-fw pi-cog",
      items: [
        {
          label: "Cấu hình mã hóa",
          icon: "pi pi-fw pi-shield",
          command: () => history.push("/ma-hoa"),
        },
        {
          label: "Cấu hình thông tin TVAN",
          icon: "pi pi-fw pi-id-card",
          command: () => history.push("/thong-tin"),
        },
      ],
    },
    {
      label: "Báo cáo",
      icon: "pi pi-fw pi-cog",
      items: [
        {
          label: "Đối Soát",
          icon: "pi pi-fw pi-shield",
          command: () => history.push("/doi-soat"),
        },
        {
          label: "Đối soát tự động",
          icon: "pi pi-fw pi-shield",
          command: () => history.push("/doi-soat-tu-dong"),
        },
        {
          label: "Lấy đối soát",
          icon: "pi pi-fw pi-shield",
          command: () => history.push("/lay-lai-doi-soat"),
        }
      ],
    },
  ];
  const itemsUser = [
    {
      label: "Lịch sử giao dịch",
      icon: "pi pi-fw pi-folder-open",
      command: () => history.push("/lich-su-giao-dich"),
    },
    {
      label: "Công cụ hỗ trợ",
      icon: "pi pi-fw pi-cog",
      items: [
        {
          label: "Gửi thông điệp/XML",
          icon: "pi pi-fw pi-inbox",
          command: () => history.push("/gui-hoa-don"),
        },
        {
          label: "Gửi lại thông diệp TCT",
          icon: "pi pi-fw pi-inbox",
          command: () => history.push("/gui-td-tct"),
        },
        {
          label: "Kiểm tra thông diệp",
          icon: "pi pi-fw pi-inbox",
          command: () => history.push("/check-thongdiep-bill"),
        },
      ],
    },
    
    
    {
      label: "Quản lý nhà cung cấp hóa đơn",
      icon: "pi pi-fw pi-th-large",
      items: [
        {
          label: "Quản lý người dùng",
          icon: "pi pi-fw pi-user",
          command: () => history.push("/nguoi-dung"),
        },
        {
          label: "Quản lý doanh nghiệp",
          icon: "pi pi-fw pi-users",
          command: () => history.push("/doanh-nghiep"),
        },
      ],
    },
    {
      label: "Cấu hình",
      icon: "pi pi-fw pi-cog",
      items: [
        {
          label: "Cấu hình mã hóa",
          icon: "pi pi-fw pi-shield",
          command: () => history.push("/ma-hoa"),
        },
        {
          label: "Cấu hình thông tin TVAN",
          icon: "pi pi-fw pi-id-card",
          command: () => history.push("/thong-tin"),
        },
      ],
    },
    
  ];
  const start = (
    <div>
      <img
        src={imageLogo}
        style={{ width: "100%", height: "30px",paddingRight: "10px" }}
        alt="Logo"
      />{" "}
    </div>
  );

  const end = (
    <ul
      className={"p-menubar-root-list menubar-right"}
      role="menubar"
      onMouseOver={onMouseOver}
      onMouseOut={() => setActive(false)}
    >
      <li
        role="none"
        className={active ? "p-menuitem p-menuitem-active" : "p-menuitem"}
      >
        <span role="menuitem" className="p-menuitem-link" aria-haspopup="true">
          <span className="p-menuitem-text">
            {keycloak?.authenticated ? keycloak?.idTokenParsed?.name : ""}
          </span>
          <span className="p-submenu-icon pi pi-angle-down" />
        </span>
        <ul
          className="p-submenu-list menubar-right menu-admin mr-5"
          role="menu"
        >
          {!!keycloak?.authenticated && (
            <li role="none">
              <span
                role="menuitem"
                className="p-menuitem-link"
                aria-haspopup="false"
                onClick={handleLogout}
              >
                <span
                  className="pi pi-sign-out"
                  style={{ marginRight: "0.5rem" }}
                />
                <span className="p-menuitem-text">Đăng xuất</span>
              </span>
            </li>
          )}
        </ul>
      </li>
    </ul>
  );
  return (
    <div className="menu">
      <div className="card">
        {
          admin?<Menubar start={start} model={itemsAdmin} end={end} />
          :
          <Menubar start={start} model={itemsUser} end={end} />
        }
        
      </div>
    </div>
  );
}

export default MenuBar;
