import axiosClient from "./AxiosClient";

const doiSoatService = {
    getAllDoiSoat: (param) => {
        const url = `/bill-gate/doi-soat/find-page?page=${param.page}&size=${param.size}&search=${param.search}`;
        return axiosClient
          .get(url)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {});
      },
      getAllDoiSoatTuDong: (param) => {
        const url = `/bill-gate/doi-soat-tu-dong/find-page?page=${param.page}&size=${param.size}&search=${param.search}`;
        return axiosClient
          .get(url)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {});
      },
}
export default doiSoatService;