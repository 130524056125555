import axiosClient from "./AxiosClient";

const userManagementService = {
  getAllUserManagement: (param) => {
    const url = `/bill-gate/nguoi-dung/find-page?page=${param.page}&size=${param.size}&sort=createdDate,DESC&search=${param.search}`;
    return axiosClient
      .get(url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {});
  },
  getUserManagementByLogin: () => {
    const url = `/bill-gate/nguoi-dung/chi-tiet-nguoi-dung-dang-nhap`;
    return axiosClient
      .get(url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {});
  },

  saveUserManagement: (data) => {
    const url = `/bill-gate/nguoi-dung/them`;
    return axiosClient
      .post(url, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },

  editUserManagement: (data) => {
    const url = `/bill-gate/nguoi-dung/sua`;
    return axiosClient
      .post(url, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },

  deleteUserManagement: (id) => {
    const url = `/bill-gate/nguoi-dung/xoa?id=${id}`;
    return axiosClient
      .post(url, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  checkRoleAdmin: () => {
    const url = `/bill-gate/nguoi-dung/Check-role`;
    return axiosClient
      .get(url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {});
  },
};
export default userManagementService;
