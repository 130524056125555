import React, { useState } from 'react';
import { FileUpload } from 'primereact/fileupload';

export const FileUploadCom = (props) => {
    const { refFile } = props;;
    const [fileName, setFileName] = useState("Tên File");
    const onChangeUploadFiles = async (e) => {
        let file = e.files[0];
        console.log(file)
        setFileName(file.name)
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          
            props.setBase64excel(reader.result.substring(reader.result.indexOf(',') + 1));

        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    return (
        <div className='InputUploadFileSign'>
            <h5>Kiểm tra mã thông diệp của nhà giải pháp</h5>
            <FileUpload
                id='up_manual'
                mode='basic'
                ref={refFile}
                uploadHandler={onChangeUploadFiles}
                customUpload={true}
                name='upload_manual'
                chooseLabel='Chọn file tải lên'
                accept='.xlsx'
                disabled={false}
                multiple={false}
                className="w-xs-100 w-md-auto"
            />




            <div>{fileName}</div>

        </div>
    )
}