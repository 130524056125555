// ** Router Components
import './index.css'
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import { ToastContainer } from "react-toastify";
import AppRouter from './page/router/AppRouter';

function App() {

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: "login-required",
      }}
    >
      <div className="bill">
          <AppRouter />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ReactKeycloakProvider>
  );
}

export default App;
