/* eslint-disable react-hooks/exhaustive-deps */
import { withKeycloak } from "@react-keycloak/web";
import React, { useEffect } from "react";
import Notification from "../../hooks/Notification";
import Main from "../../component/MenuBar/Main";

const AppRouter = ({ keycloak, keycloakInitialized }) => {
    const notification = new Notification();
    useEffect(async () => {
        if (keycloak.token === "") {
            notification.notifyWarn("Không thể kết nối tới máy chủ đăng nhập");
        }
        if (keycloak.token) {
            localStorage.setItem('token', keycloak.token);
            localStorage.setItem('username', keycloak.idTokenParsed.name);
        }else {
            localStorage.setItem('token', '');
        }

    }, [keycloak]);

    return (
        <div>
            {keycloak && keycloak.authenticated && <Main />}
        </div>
    );
};
export default withKeycloak(AppRouter);