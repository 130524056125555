import axiosClient from "./AxiosClient";
import axiosClientXML from "./AxiosclientXML";

const billService = {
  getAllBill: (page,size,param) => {
    const url = `/bill-gate/lich-su-giao-dich/find-page-new?page=${page}&size=${size}&sort=createdate,DESC`;
    return axiosClient
      .post(url,param)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {});
  },

  getAllBillByCode: (param) => {
    const url = `/bill-gate/lich-su-giao-dich/lay-chi-tiet-by-ma-nhom-giao-dich?maNhomGiaoDich=${param}`;
    return axiosClient
      .get(url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {});
  },
  getThongDiepByCode: (param) => {
    const url = `/bill-gate/thong-diep-hoa-don/find-matd?ma=${param}`;
    return axiosClient
      .get(url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {});
  },
  getSendXml: (data) => {
    const url = `/bill-gate/xml/send-xml-from-bill`;
    return axiosClientXML
      .post(url, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },

  sendXmlTct: (data) => {
    const url = `/bill-gate/xml/send-xml-from-tct`;
    return axiosClientXML
      .post(url, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  viewXml: (data) => {
    const url = `/bill-gate/xml/view-xml?matd=${data.matd}&loaitb=${data.loaitb}`;
    return axiosClientXML
      .get(url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  viewXmlDoiSoat: (data) => {
    const url = `/bill-gate/xml/view-xml-doisoat?matd=${data.matd}&loaitb=${data.loaitb}`;
    return axiosClientXML
      .get(url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  layLaiDoiSoat: (data) => {
    const url = `/bill-gate/doi-soat/laylaidoisoat`;
    return axiosClient
      .post(url,data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};
export default billService;
