import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Notification {
    notifySuccess = (message) => {
        toast.success(` ${message}`, {
            position: "top-right",
        });
    };

    notifyError = (message) => {
        toast.error(` ${message}`, {
            position: "top-right",
        });
    };

    notifyWarn = (message) => {
        toast.warn(` ${message}`, {
            position: "top-right",
        });
    };

    notifyInfo = (message) => {
        toast.info(` ${message}`, {
            position: "top-right",
        });
    };

    clearWaitingQueue = () => {
        toast.clearWaitingQueue();
    };
}

export default Notification;
