import React from 'react';
import DoiSoatMain from './page/DoiSoat';
import DoiSoatTuDongMain from './page/DoiSoatTuDong';
import ListBill from "./page/HistoryTransaction/ListBill";
import BusinessManagement from './page/InvoiceSupplierManagement/BusinessManagement/BusinessManagement';
import UserManagement from './page/InvoiceSupplierManagement/UserManagement/UserManagement';
import CheckThongDiep from './page/KiemTraThongDiep/CheckThongDiep';
import LayLaiDoiSoat from './page/LayLaiDoiSoat/LayLaiDoiSoat';
import SettingInfoTVAN from './page/Setting/SettingInfoTVAN';
import TctSend from './page/TCTSend/TctSend';
import SentXml from "./page/XML/SentXml";


const routes = [

    {
        path: '/lich-su-giao-dich',
        exact: true,
        main: () => <ListBill />

    },
    {
        path: '/',
        exact: true,
        main: () => <ListBill />

    },
    {
        path: '/gui-hoa-don',
        exact: true,
        main: () => <SentXml />

    },
    {
        path: '/gui-td-tct',
        exact: true,
        main: () => <TctSend />

    },
    {
        path: '/check-thongdiep-bill',
        exact: true,
        main: () => <CheckThongDiep />

    },
    {
        path: '/doanh-nghiep',
        exact: true,
        main: () => <BusinessManagement />
    },
    {
        path: '/nguoi-dung',
        exact: true,
        main: () => <UserManagement />
    },
    // {
    //     path: '/ma-hoa',
    //     exact: true,
    //     main: () => <TctSend />
    // },
    {
        path: '/thong-tin',
        exact: true,
        main: () => <SettingInfoTVAN />
    },
    {
        path: '/doi-soat',
        exact: true,
        main: () => <DoiSoatMain />
    },
    {
        path: '/doi-soat-tu-dong',
        exact: true,
        main: () => <DoiSoatTuDongMain />
    },
    {
        path: '/lay-lai-doi-soat',
        exact: true,
        main: () => <LayLaiDoiSoat />
    }

];

export default routes;