import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useState } from "react";
import ShowDetailBill from "./ShowDetailBill";
import billService from "../../service/BillService";
import moment from "moment";

const ShowNotificationBill = (props) => {
  const { visiable, onHideDialog, billDetails } = props;
  const [viewXMLDialog, setViewXMlDialog] = useState(false);
  const [xmlBill, setxmlBill] = useState("");
  
  async function onHideOnNoDialog() {
    onHideDialog();
  }
  const hidenDialog = () => {
    setViewXMlDialog(false);
  };
  const onShowClick = async (data) => {
    let matd = data.mathongdiep;
    let loaitb = data.loaitb;
    let datareq = {matd,loaitb};
    let getxml = await billService.viewXml(datareq);
    console.log(getxml);
    setxmlBill(getxml.data);
    setViewXMlDialog(true);
  };

  const renderCreateDate = (rowData) => {
    return (
      <>
        {rowData.createdate !== null
          ? moment(rowData.createdate).format("MM/DD/YYYY HH:mm:ss")
          : ""}
      </>
    );
  };
  const renderTacVu = (data) => {
    return (
      <div>
        <i
          className="pi pi-eye"
          style={{ color: "blue", cursor: "pointer", textAlign: "center" }}
          onClick={() => onShowClick(data)}
          title={"Chi tiết"}
        />
      </div>
    );
  };
  const renderFooter = () => {
    return (
      <div style={{textAlign:'center'}}>
        <Button
          label="Đóng"
          icon="pi pi-times"
          onClick={onHideOnNoDialog}
          className="p-button-text"
        />
      </div>
    );
  };
  return (
    <div>
      <Dialog
        header="Danh Sách thông báo"
        visible={visiable}
        onHide={onHideOnNoDialog}
        footer={renderFooter}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "70vw" }}
        baseZIndex={1000}
      >
        <DataTable value={billDetails} emptyMessage="Không có dữ liệu">
        <Column field={"maloaithongdiep"} header="Mã loại thông điệp" />
        <Column field={"mathongdiep"} header="Mã thông điệp" />
        <Column
          field={"pban"}
          header="Phiên bản"
          className="p-text-nowrap p-text-truncate"
        />

        <Column field={"mngui"} header="Mã người gửi" />
        <Column field={"mnnhan"} header="Mã người nhận" />
          <Column
            field={"createdDate"}
            header="Ngày nhận"
            body={renderCreateDate}
          />
          <Column
            field={"tenhoso"}
            header="Tác vụ"
            className="p-text-center"
            body={renderTacVu}
          />
        </DataTable>
      </Dialog>

      <ShowDetailBill
        viewXMLDialog={viewXMLDialog}
        onHideDialog={hidenDialog}
        xmlBill={xmlBill}
      />
    </div>
  );
};

export default ShowNotificationBill;
