import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useState } from "react";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import doiSoatService from "../../service/DoiSoatService";

function DoiSoatMain() {
  const [first, setFirst] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [paginate, setPaginate] = useState({
    page: 0,
    size: 10,
  });
  const [search, setSearch] = useState({
    search: "",
  });
  useEffect(() => {
    getAllService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, paginate]);
  const getAllService = async () => {
    showLoader();
    const dataBody = { ...paginate, ...search };
    const result = await doiSoatService.getAllDoiSoat(dataBody);
    console.log(result);
    if (result && result.status === 1000) {
      setDoiSoats(result.data);
      setTotalRecord(result.count);
    }
    hideLoader();
  };
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [doiSoats, setDoiSoats] = useState([]);

  
  const onHandleChangeSearch = (e) => {
    console.log(e.target.name);
    if (e.target.value.length < 255) {
      setSearch({ ...search, [e.target.name]: e.target.value });
    }
  };
  const leftContents = (
    <React.Fragment>
      <InputText
        className={"p-mr-3"}
        value={search.search}
        onChange={onHandleChangeSearch}
        tooltip={"Nhập mã thông diệp"}
        name={"search"}
        placeholder={"Nhập mã thông diệp"}
      />
    </React.Fragment>
  );
  const onHandleSearchClick = async () => {
    setFirst(0);
    showLoader();
    const dataBody = { ...paginate, ...search };
    const result = await doiSoatService.getAllDoiSoat(dataBody);
    if (result && result.status === 1000) {
      setDoiSoats(result.data);
      setTotalRecord(result.count);
    }
    hideLoader();
  };
  const onHandleRefresh = () => {
    setFirst(1);
    setSearch({
      search: "",
    });

    setPaginate({
      page: 0,
      size: 10,
    });
  };
  const rightContents = (
    <React.Fragment>
      <Button
        icon="pi pi-search"
        title="Tìm kiếm"
        className="p-mr-2"
        onClick={onHandleSearchClick}
      />
      &ensp;
      <Button
        icon="pi pi-refresh"
        title="Làm mới"
        className="p-mr-2 p-button-help"
        onClick={onHandleRefresh}
      />
    </React.Fragment>
  );
  const renderRowIndex = (rowData, column) => {
    return column.rowIndex + 1 + first;
  };
  const renderCreateDate = (date) => {
    return (
      <>
        {date !== null
          ? moment(date).format("DD/MM/YYYY HH:mm:ss")
          : ""}
      </>
    );
  };
  const b64toBlob = (base64Data, contenttype) => {
    var byteString = atob(base64Data);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: contenttype });
  };

  const onShowClick = async (rowData) => {
    var fileDownload = require("js-file-download");
      fileDownload(
        b64toBlob(
            rowData.datafile,
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ),
        rowData.ngaygui+".xlsx"
      );

  }
  const renderTacVu = (data) => {
    return (
      <div>
        <i
          className="pi pi-envelope"
          style={{
            color: "blue",
            cursor: "pointer",
            textAlign: "center",
            paddingRight: "10px",
          }}
          onClick={() => onShowClick(data)}
          title={"Tài xuông"}
        />
      </div>
    );
  };
  const onPageChange = (event) => {
    setFirst(event.first);
    setPaginate({ ...paginate, size: event.rows, page: event.page });
  };
  return (
    <div className="card">
      <div className={"card-header"}>
        <Toolbar left={leftContents} right={rightContents} />
      </div>
      <DataTable value={doiSoats} emptyMessage="Không có dữ liệu">
        <Column
          body={renderRowIndex}
          header="STT"
          headerStyle={{ width: "4rem" }}
        />
        <Column field={"ngaygui"} header="Ngày gửi" 
        body={rowData => renderCreateDate(rowData.ngaygui)}
        />
        <Column field={"tungay"} header="Từ ngày"
        body={rowData => renderCreateDate(rowData.tungay)}
        />
        <Column
          field={"denngay"}
          header="Đến ngày"
          className="p-text-nowrap p-text-truncate"
          body={rowData => renderCreateDate(rowData.denngay)}
        />

        {/* <Column field={"mngui"} header="Mã người gửi" />
        <Column field={"mnnhan"} header="Mã người nhận" /> */}
        <Column
          field={"ngaygui"}
          header="Thời gian tạo"
          body={rowData => renderCreateDate(rowData.ngaygui)}
        />
        <Column
          field={"tenhoso"}
          header="Tác vụ"
          className="p-text-center"
          body={renderTacVu}
        />
      </DataTable>

      <div className="p-d-flex p-mt-2">
        <div className="p-mt-3">
          <span>
            Tổng số <b>{totalRecord}</b> bản ghi
          </span>
        </div>
        <div className="p-ml-auto">
          <Paginator
            first={first}
            rows={paginate.size}
            totalRecords={totalRecord}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageChange={(event) => onPageChange(event)}
            template=" RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          />
        </div>
      </div>
      {loader}
    </div>
  );
}

export default DoiSoatMain;
