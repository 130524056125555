import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Footer } from "./Footer";
import Content from "./Content";
import MenuBar from "./MenuBar";
import { useKeycloak } from "@react-keycloak/web";

const Main = () => {
    const { keycloak } = useKeycloak();
    if (keycloak?.authenticated) {
        localStorage.setItem('token', keycloak.token);
        localStorage.setItem('username', keycloak.idTokenParsed.preferred_username);
    }

    return (
        <Router>
            <div >
                <MenuBar />
                <Content />
                <Footer />
            </div>
        </Router>
    );

};

export default Main;