import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

const PopupConfirm = (props) => {
  const { visible, onHideDialog, handleOk, typeAd, messageHeader, messagaeBody } = props;
  const renderFooter = () => {
    return (
      <div style={{textAlign:'center'}}>
        <Button
          label="Hủy"
          icon="pi pi-times"
          onClick={onHideDialog}
          className="p-button-text p-button-danger"
        />

        <Button
          label="Đồng ý"
          icon="pi pi-check"
          className="p-button-success"
          onClick={handleOk}
        />
      </div>
    );
  };
  const renderHeader = () => {
    return (
      <p className='pi pi-exclamation-triangle' style={{fontWeight: "700",display:'flex',justifyContent:'center'}}>
        {messageHeader}
      </p>
    );
  };
  return (
    <div>
      <Dialog
        header={renderHeader}
        visible={visible}
        onHide={onHideDialog}
        style={{ width: "20vw" }}
        footer={typeAd === "3" ? "" : renderFooter}
      >
        <div>
    
          <h5 style={{ fontWeight: "700",textAlign:'center', }}>
            {messagaeBody}
          </h5>
        </div>
      </Dialog>
    </div>
  );
};

export default PopupConfirm;
