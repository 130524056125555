import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import XMLViewer from 'react-xml-viewer'
function ShowDetailBill(props) {
  const { viewXMLDialog, onHideDialog, xmlBill } = props;
 
  

  function onHideOnNoDialog() {
    onHideDialog();
  }
  const convertstringtoblob = (str,contenttype)=>{
    const blob = new Blob([str], {
      type: contenttype // or whatever your Content-Type is
    });
    return blob;
  }
  
  function downloadXml(xmlBill) {
    console.log("111111111111111111111111111");
    console.log(xmlBill);
    var fileDownload = require("js-file-download");
    fileDownload(
      convertstringtoblob(
        xmlBill,
        "text/plain"
      ),
      "thongdiep.xml"
    );
  }

  const renderFooter = () => {
    return (
      <div className={"p-text-center"}>
        <Button
          label="Đóng"
          icon="pi pi-times"
          onClick={onHideOnNoDialog}
          className="p-button-text"
        />
        <Button
          label="Tải xml"
          icon="pi pi-download"
          onClick={()=>downloadXml(xmlBill)}
          className="p-button-text"
        />
      </div>
    );
  };
  return (
    <div>
      <Dialog
        header="Thông tin xml thông diệp"
        visible={viewXMLDialog}
        onHide={onHideDialog}
        footer={renderFooter}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "70vw" }}
        baseZIndex={1000}
      >
        <div>
          <XMLViewer xml={xmlBill}  />
          
        </div>
      </Dialog>
    </div>
  );
}

export default ShowDetailBill;
