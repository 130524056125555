import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useState } from "react";
import userManagementService from "../../../service/UserManagementService";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import Notification from "../../../hooks/Notification";
import "./style.css";
import { Tag } from "primereact/tag";
import {
  EMAIL_REGEX,
  MESSAGE_EMAIL_FORMAT_ERROR,
  MESSAGE_GIOIHAN255_KYTU,
  MESSAGE_PHONE_FORMAT_ERROR,
  MESSAGE_REQUIRE,
} from "../../../component/constants/ConstantString";

const PopupCreateNewUsers = (props) => {
  const { visible, onHideDialog, typeAd, dataUser, setDataUser, fetDataUser } =
    props;
  const notification = new Notification();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [thuDienTuErrors, setThuDienTuErrors] = useState({});
  const [tenNguoiDungErrors, setTenNguoiDungErrors] = useState({});
  const [soDienThoaiErrors, setSoDienThoaiErrors] = useState({});
  const [matKhauErrors, setMatKhauErrors] = useState({});
  const [tenDangNhapErrors, setTenDangNhapErrors] = useState({});

  const updateField = (data, field) => {
    setDataUser({
      ...dataUser,
      [field]: data,
    });
  };

  // check form after submit form
  const formValidation = () => {
    const tennguoidungErrors = { tennguoidungRequired: "" };
    const thudientuErrors = { thudientuRequired: "" };
    const sodienthoaiErrors = { sodienthoaiRequired: "" };
    const tendangnhapErrors = { tendangnhapRequired: "" };
    const matkhauErrors = { matkhauRequired: "" };

    let isValid = true;
    if (dataUser.hoTen === "" || dataUser.hoTen == null) {
      tennguoidungErrors.tennguoidungRequired = MESSAGE_REQUIRE;
      isValid = false;
    } else if (dataUser.hoTen.length > 255) {
      tennguoidungErrors.tennguoidungRequired = MESSAGE_GIOIHAN255_KYTU;
      isValid = false;
    }

    if (dataUser.tenDangNhap === "" || dataUser.tenDangNhap == null) {
      tendangnhapErrors.tendangnhapRequired = MESSAGE_REQUIRE;
      isValid = false;
    } else if (dataUser.tenDangNhap.length > 255) {
      tendangnhapErrors.tendangnhapRequired = MESSAGE_GIOIHAN255_KYTU;
      isValid = false;
    }

    if (dataUser.thuDienTu === "" || dataUser.thuDienTu == null) {
      thudientuErrors.thudientuRequired = MESSAGE_REQUIRE;
      isValid = false;
    } else if (!EMAIL_REGEX.test(dataUser.thuDienTu)) {
      thudientuErrors.thudientuRequired = MESSAGE_EMAIL_FORMAT_ERROR;
      isValid = false;
    } else if (dataUser.thuDienTu.length > 50) {
      thudientuErrors.thudientuRequired = "Vui lòng nhập bé hơn 50 ký tự !";
      isValid = false;
    }

    if (
      dataUser.soDienThoai === "" ||
      (dataUser.soDienThoai == null && dataUser.soDienThoai.length >= 10)
    ) {
      sodienthoaiErrors.sodienthoaiRequired = MESSAGE_PHONE_FORMAT_ERROR;
      isValid = false;
    } 
    // else if (!PHONE_NUMBER_REGEX.test(dataUser.soDienThoai)) {
    //   sodienthoaiErrors.sodienthoaiRequired =
    //     "Vui lòng nhập đúng định dạng số điện thoại";
    //   isValid = false;
    // }

    if (dataUser.matKhau === "" || dataUser.matKhau == null) {
      matkhauErrors.matkhauRequired = MESSAGE_REQUIRE;
      isValid = false;
    } else if (dataUser.matKhau.length > 255) {
      matkhauErrors.matkhauRequired = MESSAGE_GIOIHAN255_KYTU;
      isValid = false;
    }

    setTenNguoiDungErrors(tennguoidungErrors);
    setTenDangNhapErrors(tendangnhapErrors);
    setMatKhauErrors(matkhauErrors);
    setThuDienTuErrors(thudientuErrors);
    setSoDienThoaiErrors(sodienthoaiErrors);
    return isValid;
  };

  const handleSaveOrEdit = async () => {
    showLoader();
    let isValid = formValidation();
    if (isValid) {
      if (typeAd === "1") {
        const result = await userManagementService.saveUserManagement(dataUser);
        if (result && result.status === 1000) {
          setTimeout(fetDataUser, 500); // đợi 0.5s sau mới gọi hàm fetData()
          onHideDialog();
          let message = result.message;
          notification.notifySuccess(message);
        } else {
          let message = result.message;
          notification.notifyError(message);
        }
      } else if (typeAd === "2") {
        // function update
        const result = await userManagementService.editUserManagement(dataUser);
        if (result && result.status === 1000) {
          setTimeout(fetDataUser, 500); // đợi 0.5s sau mới gọi hàm fetData()
          onHideDialog();
          let message = result.message;
          notification.notifySuccess(message);
        } else {
          let message = result.message;
          notification.notifyError(message);
        }
      }
    }

    hideLoader();
  };

  const hiddenPopUp = () => {
    onHideDialog();
    setTenNguoiDungErrors("");
    setTenDangNhapErrors("");
    setMatKhauErrors("");
    setThuDienTuErrors("");
    setSoDienThoaiErrors("");
  };

  const renderHeader = () => {
    if (typeAd === "1") {
      return <p>Tạo mới người dùng</p>;
    } else if (typeAd === "2") {
      return <p>Sửa người đùng</p>;
    } else if (typeAd === "3") {
      return <p>Xem người đùng</p>;
    }
  };
  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Hủy"
          icon="pi pi-times"
          onClick={hiddenPopUp}
          className="p-button-text p-button-danger"
        />
        <Button
          label="Xác nhận"
          icon="pi pi-check"
          className="p-button-success"
          onClick={handleSaveOrEdit}
        />
      </div>
    );
  };

  return (
    <div className="popup-create-user">
      {loader}
      <Dialog
        header={renderHeader}
        visible={visible}
        onHide={hiddenPopUp}
        style={{ width: "70vw" }}
        footer={typeAd === "3" ? "" : renderFooter}
      >
        <div className="p-fluid p-formgrid p-grid form-user">
          <div className="p-field p-col user-col-left">
            <label htmlFor="mst">Mã số thuế</label>
            <InputText
              id="mst"
              placeholder="Mã số thuế"
              value={dataUser.mst || ""}
              name="mst"
              disabled={true}
              onChange={(e) => updateField(e.target.value, "mst")}
            />
          </div>
          <div className="p-field p-col user-col-right">
            <label htmlFor="hoTen">Tên người dùng</label>
            <InputText
              id="hoTen"
              placeholder="Tên người dùng"
              value={dataUser.hoTen || ""}
              name="hoTen"
              onChange={(e) => updateField(e.target.value, "hoTen")}
            />
            {Object.keys(tenNguoiDungErrors).map((keyIndex, key) => {
              return (
                <span className="p-error" key={key}>
                  {tenNguoiDungErrors[keyIndex]}
                </span>
              );
            })}
          </div>
        </div>

        <div className="p-fluid p-formgrid p-grid form-user">
          <div className="p-field p-col user-col-left">
            <label htmlFor="thuDienTu">Thư điện tử</label>
            <InputText
              id="thuDienTu"
              placeholder="Thư điện tử"
              value={dataUser.thuDienTu || ""}
              onChange={(e) => updateField(e.target.value, "thuDienTu")}
              name="thuDienTu"
            />
            {Object.keys(thuDienTuErrors).map((keyIndex, key) => {
              return (
                <span className="p-error" key={key}>
                  {thuDienTuErrors[keyIndex]}
                </span>
              );
            })}
          </div>
          <div className="p-field p-col user-col-right">
            <label htmlFor="soDienThoai">Số điện thoại</label>
            <InputText
              id="soDienThoai"
              placeholder="Số điện thoại"
              name="soDienThoai"
              onChange={(e) => updateField(e.target.value, "soDienThoai")}
              value={dataUser.soDienThoai || ""}
            />
            {Object.keys(soDienThoaiErrors).map((keyIndex, key) => {
              return (
                <span className="p-error" key={key}>
                  {soDienThoaiErrors[keyIndex]}
                </span>
              );
            })}
          </div>
        </div>

        <div className="p-fluid p-formgrid p-grid form-user">
          <div className="p-field p-col user-col-left">
            <label htmlFor="tenDangNhap">Tên đăng nhập</label>
            <InputText
              id="tenDangNhap"
              placeholder="Tên đăng nhập"
              name="tenDangNhap"
              value={dataUser.tenDangNhap || ""}
              onChange={(e) => updateField(e.target.value, "tenDangNhap")}
            />
            {Object.keys(tenDangNhapErrors).map((keyIndex, key) => {
              return (
                <span className="p-error" key={key}>
                  {tenDangNhapErrors[keyIndex]}
                </span>
              );
            })}
          </div>
          <div className="p-field p-col user-col-right">
            <label htmlFor="matKhau">Mật khẩu</label>
            <InputText
              id="matKhau"
              placeholder="Mật khẩu"
              name="matKhau"
              value={dataUser.matKhau || ""}
              onChange={(e) => updateField(e.target.value, "matKhau")}
            />
            {Object.keys(matKhauErrors).map((keyIndex, key) => {
              return (
                <span className="p-error" key={key}>
                  {matKhauErrors[keyIndex]}
                </span>
              );
            })}
          </div>
        </div>

        <div className="p-fluid p-formgrid p-grid form-user">
          <div className="p-field p-col-12 user-col-mota">
            <label htmlFor="address">Mô tả</label>
            <InputTextarea
              type="text"
              rows={3}
              cols={5}
              value={dataUser.moTa || ""}
              onChange={(e) => updateField(e.target.value, "moTa")}
            />
          </div>
        </div>
        {(typeAd === "2" || typeAd === "3") && (
          <div className="p-fluid p-formgrid p-grid form-user">
            <div className="p-field p-col user-col-left">
              <label htmlFor="tenDangNhap">Trạng thái</label>
              <p>
                {dataUser.trangThai === 0 ? (
                  <Tag severity="info" value={"Đang sử dụng"} />
                ) : (
                  <Tag severity="warning" value={"Đã cập nhật"} />
                )}
              </p>
            </div>
            <div className="p-field p-col user-col-right">
              <label htmlFor="matKhau">Hoạt động</label>
              <p>
                {dataUser.trangThai === 0 ? (
                  <Tag severity="info" value={"Hoạt động"} />
                ) : (
                  <Tag severity="warning" value={"Khóa"} />
                )}
              </p>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default PopupCreateNewUsers;
