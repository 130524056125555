import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useState } from "react";
import Notification from "../../hooks/Notification";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import billService from "../../service/BillService";
import "./index.css";

function TctSend() {
  const notification = new Notification();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [dataXml, setDataXML] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  const sendXMLTct = async () => {
    if(dataXml !== null && dataXml !== '') {
      showLoader();
      const result = await billService.sendXmlTct(dataXml);
      if (result && result.status === 1000) {
        setDisableButton(true);
        notification.notifySuccess(result.message);
      } else {
        notification.notifyError(result.message);
      }
      hideLoader();
    } else {
      notification.notifyWarn('Vui lòng nhập dữ liệu');
    }
  };
  const updateField = (value) => {
    setDataXML(value);
  };
 
  return (
    <div className="sentXML">
      <div className="card">
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12">
            <label htmlFor="address">Thông diệp từ TCT</label>
            <InputTextarea
              type="text"
              rows={20} cols={30}
              onChange={(e) => updateField(e.target.value)}
            />
          </div>
        </div>
        <div className='button-xml'>
          <Button
            label="Gửi"
            className="p-button-success"
            disabled={disableButton}
            onClick={sendXMLTct}
          />
        </div>
        {loader}
      </div>
    </div>
  );
}

export default TctSend;
