export const EMAIL_REGEX = RegExp(
  /^[a-zA-Z0-9.’*+/=?_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);
export const USERNAME_REGEX = /^[a-zA-Z0-9_-]*$/;
export const PHONE_NUMBER_REGEX = /(09|08|01[2|6|8]|03[2|4|6|8])+([0-9]{8})\b/;
export const MST_REGEX = /^([0-9]{10})+[-]+([0-9]{3})\b/;
export const MST_REGEX_CASE_14 = /^([0-9]{10})+[-]+([0-9]{3})\b/;
export const MST_REGEX_CASE_10 = /^([0-9]{10})\b/;

export const MESSAGE_REQUIRE = "Không được bỏ trống";
export const MESSAGE_EMAIL_FORMAT_ERROR = "Không đúng định dạng email";
export const MESSAGE_PHONE_FORMAT_ERROR =
  "Số điện thoại phải gồm có 10 số và đúng định dạng";
export const MESSAGE_STK_FORMAT_ERROR = "Số tài khoản phải gồm có 9 đến 14 số";
export const MESSAGE_GIOIHAN255_KYTU = "Vui lòng nhập bé hơn 255 ký tự !";