import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import Notification from "../../hooks/Notification";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import businessManagementService from "../../service/BusinessManagementService";
import "./style.css";
const SettingInfoTVAN = () => {
  const notification = new Notification();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [dataUser, setDataUser] = useState({
    tenDoanhNghiep: "",
    tenNguoiDaiDien: "",
    thuDienTu: "",
    soDienThoai: "",
    mst: "",
    diaChi: "",
  });

  useEffect(() => {
    getInfoTVANByLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateField = (data, field) => {
    setDataUser({
      ...dataUser,
      [field]: data,
    });
  };

  const getInfoTVANByLogin = async () => {
    showLoader();
    try {
      const result =
        await businessManagementService.getBusinessManagementByLogin();
      if (result && result.status === 1000) {
        setDataUser({
          ...result.data,
        });
      } else {
        let message = result.message;
        notification.notifyError(message);
      }
    } catch (e) {
      notification.notifyError("Lỗi kết nối");
    }
    hideLoader();
  };

  return (
    <div className="info-tvan">
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
        Thông tin TVAN
      </h2>
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-field p-col user-col">
          <label htmlFor="mst">Mã số thuế</label>
          <InputText
            id="mst"
            placeholder="Mã số thuế"
            value={dataUser.mst || ""}
            name="mst"
            disabled={true}
            onChange={(e) => updateField(e.target.value, "mst")}
          />
        </div>
        <div className="p-field p-col user-col">
          <label htmlFor="tenDoanhNghiep">Tên doanh nghiệp</label>
          <InputText
            id="tenDoanhNghiep"
            placeholder="Tên người dùng"
            value={dataUser.tenDoanhNghiep || ""}
            name="tenDoanhNghiep"
            disabled={true}
            onChange={(e) => updateField(e.target.value, "tenDoanhNghiep")}
          />
        </div>
      </div>

      <div className="p-fluid p-formgrid p-grid">
        <div className="p-field p-col user-col">
          <label htmlFor="thuDienTu">Thư điện tử</label>
          <InputText
            id="thuDienTu"
            placeholder="Thư điện tử"
            value={dataUser.thuDienTu || ""}
            onChange={(e) => updateField(e.target.value, "thuDienTu")}
            disabled={true}
            name="thuDienTu"
          />
        </div>
        <div className="p-field p-col user-col">
          <label htmlFor="soDienThoai">Số điện thoại</label>
          <InputText
            id="soDienThoai"
            placeholder="Số điện thoại"
            name="soDienThoai"
            onChange={(e) => updateField(e.target.value, "soDienThoai")}
            value={dataUser.soDienThoai || ""}
            disabled={true}
          />
        </div>
      </div>

      <div className="p-fluid p-formgrid p-grid">
        <div className="p-field p-col user-col">
          <label htmlFor="tenNguoiDaiDien">Tên người đại diện</label>
          <InputText
            id="tenNguoiDaiDien"
            placeholder="Tên người đại diện"
            value={dataUser.tenNguoiDaiDien || ""}
            onChange={(e) => updateField(e.target.value, "tenNguoiDaiDien")}
            disabled={true}
            name="tenNguoiDaiDien"
          />
        </div>
        <div className="p-field p-col user-col">
          <label htmlFor="diaChi">Địa chỉ</label>
          <InputText
            id="diaChi"
            placeholder="Địa chỉ"
            name="diaChi"
            onChange={(e) => updateField(e.target.value, "diaChi")}
            value={dataUser.diaChi || ""}
            disabled={true}
          />
        </div>
      </div>

      {loader}
    </div>
  );
};

export default SettingInfoTVAN;
