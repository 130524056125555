import React from "react";
export const Footer = () => {
  return (
    <footer style={{ background: "#0069d9",padding:'10px'}}>
      <p className="clearfix mb-0" style={{textAlign:'center', paddingTop: '10px'}}>
        <span
          className="float-md-left d-block d-md-inline-block"
          style={{ color: "#fff" }}
        >
          COPYRIGHT © {new Date().getFullYear()}{" "}
          <a
            href="https://cyberlotus.com"
            style={{ color: "#fff" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            CyberLotus
          </a>
          <span className="d-none d-sm-inline-block">
            . All rights reserved.
          </span>
        </span>
      </p>
    </footer>
  );
};