import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import Notification from "../../hooks/Notification";
import billService from "../../service/BillService";
import "./index.css";

function LayLaiDoiSoat() {
  const notification = new Notification();
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [email, setEmail] = useState("");
  const sendXMLTct = async () => {
    console.log(startTime);
    console.log(endTime);
    let datain = {endTime,startTime,email};
    const result = await billService.layLaiDoiSoat(datain);
    console.log(result);
    notification.notifySuccess(result.message);
  };
  
 
  return (
    <div className="sentXML">
      <div className="card">
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-4">
            <p htmlFor="address">Thời gian bắt đầu</p>
            <Calendar id="time24" value={startTime} onChange={(e) => setStartTime(e.value)}  showTime showSeconds />
          </div>
          <div className="p-field p-col-4">
            <p htmlFor="address">Thời gian kết thúc</p>
            <Calendar id="time25" value={endTime} onChange={(e) => setEndTime(e.value)}  showTime showSeconds />
          </div>
          <div className="p-field p-col-4">
            <p htmlFor="address">Email</p>
            <InputText value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
        </div>
        <div className='button-xml'>
          <Button
            label="Lấy đối soát"
            className="p-button-success"
            onClick={sendXMLTct}
          />
        </div>
        
      </div>
    </div>
  );
}

export default LayLaiDoiSoat;
