import { Button } from "primereact/button";
import React, { useState, useRef } from "react";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import sendXmlService from "../../service/SendXmlApi";
import { FileUploadCom } from "./fileupload";
import "./index.css";

function CheckThongDiep() {
  // const notification = new Notification();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [base64excel, setBase64excel] = useState('');
  const refFile = useRef(null)


  const sendXML = async () => {
    try {
      showLoader();
      console.log(base64excel);
      let baseexcel = base64excel;
      let data = { baseexcel };
      let res = await sendXmlService.sendcheckmathongdiep(data);
      console.log(res);
      hideLoader();
      refFile.current.clear();
      let nameOffice = 'ket-qua-kiem-tr.xlsx';
      var fileDownload = require('js-file-download');
      fileDownload(
        b64toBlob(
          res.data,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ),
        nameOffice
      );
    } catch (error) {
      console.log('errr', error);
      refFile.current.clear();

    }

  };
  const b64toBlob = (base64Data, contenttype) => {
    var byteString = atob(base64Data);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: contenttype });
  };

  return (
    <div className="sentXML">
      <div className="card">
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12">
            <FileUploadCom setBase64excel={setBase64excel}
              refFile={refFile}
            />
          </div>
        </div>
        <div className='button-xml'>
          <Button
            label="Gửi"
            className="p-button-success"
            onClick={sendXML}
          />
        </div>
        {loader}
      </div>
    </div>
  );
}

export default CheckThongDiep;
