// api/axiosClient.js
// import { useKeycloak } from '@react-keycloak/web';
import axios from "axios";
import queryString from "query-string";
// Set up default config for http requests here

// Please have a look at here `https://github.com/axios/axios#request-config` for the full list of configs

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
  // Handle token here ...
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return {
        data: response.data,
      };
    }

    return response;
  },
  (error) => {
    // Handle errors

    // const { keycloak } = useKeycloak();
    const response = error.response;
    console.log(error);
    if (response === undefined) {
      window.location.hash = "/500";
    }
    if (response && response.status === 401) {
      window.location.hash = "/not-authorized";
    }
    if (response && response.status === 403) {
      window.location.hash = "/403";
    }
    if (response && response.status === 500) {
      window.location.hash = "/500";
    }

    throw error;
  }
);
export default axiosClient;
