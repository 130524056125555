import axiosClient from "./AxiosClient";

const sendXmlService = {
    sendxmltobill: (data) => {
        const url = `/bill-gate/xml/send-bill-again`;
        return axiosClient
          .post(url,data)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {});
      },
      sendcheckmathongdiep: (data) => {
        const url = `/bill-gate/xml/check-thong-diep`;
        return axiosClient
          .post(url,data)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {});
      },
}
export default sendXmlService;