import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useState } from "react";
import { RadioButton } from 'primereact/radiobutton';
import {
  EMAIL_REGEX,
  MESSAGE_EMAIL_FORMAT_ERROR,
  MESSAGE_GIOIHAN255_KYTU,
  MESSAGE_PHONE_FORMAT_ERROR,
  MESSAGE_REQUIRE,
} from "../../../component/constants/ConstantString";
import Notification from "../../../hooks/Notification";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import businessManagementService from "../../../service/BusinessManagementService";

const PopupCreateNewBusiness = (props) => {
  const {
    visible,
    onHideDialog,
    typeAd,
    dataBusiness,
    setDataBusiness,
    fetDataBusiness,
  } = props;
  const notification = new Notification();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [thudientuErrors, setThudientuErrors] = useState({});
  const [tendoanhnghiepErrors, setTendoanhnghiepErrors] = useState({});
  const [sodienthoaiErrors, setSodienthoaiErrors] = useState({});
  const [tennguoidaidienErrors, setTennguoidaidienErrors] = useState({});
  const [diaChiErros, setDiaChiErros] = useState({});
  const [topicInErrors, setTopicInErrors] = useState({});
  const [topicOutErrors, setTopicOutErrors] = useState({});
  const [phuongthuctruyennhan, setPhuongthuctruyennhan] = useState(0);
  const updateField = (data, field) => {
    setDataBusiness({
      ...dataBusiness,
      [field]: data,
    });
  };
  const updateFieldradio = (data, field) => {
    setPhuongthuctruyennhan(data);
    setDataBusiness({
      ...dataBusiness,
      [field]: data,
    });
  };
  // check form after submit form
  const formValidation = () => {
    const thudientuErrors = { thudientuRequired: "" };
    const diaChiErrors = { diaChiRequired: "" };
    const sodienthoaiErrors = { sodienthoaiRequired: "" };
    const tennguoidaidienErrors = { tennguoidaidienRequired: "" };
    const tenDoanhNghiepErrors = { tenDoanhNghiepRequired: "" };
    const topicInErrors = { topicInRequired: "" };
    const topicOutErrors = { topicOutRequired: "" };


    let isValid = true;

    if (dataBusiness.thuDienTu === "" || dataBusiness.thuDienTu == null) {
      thudientuErrors.thudientuRequired = MESSAGE_REQUIRE;
      isValid = false;
    } else if (!EMAIL_REGEX.test(dataBusiness.thuDienTu)) {
      thudientuErrors.thudientuRequired = MESSAGE_EMAIL_FORMAT_ERROR;
      isValid = false;
    } else if (dataBusiness.thuDienTu.length > 50) {
      thudientuErrors.thudientuRequired = "Vui lòng nhập bé hơn 50 ký tự !";
      isValid = false;
    }

    if (
      dataBusiness.soDienThoai === "" ||
      (dataBusiness.soDienThoai == null &&
        dataBusiness.soDienThoai.length >= 10)
    ) {
      sodienthoaiErrors.sodienthoaiRequired = MESSAGE_PHONE_FORMAT_ERROR;
      isValid = false;
    } 
    // else if (!PHONE_NUMBER_REGEX.test(dataBusiness.soDienThoai)) {
    //   sodienthoaiErrors.sodienthoaiRequired =
    //     "Vui lòng nhập đúng định dạng số điện thoại";
    //   isValid = false;
    // }

    if (
      dataBusiness.tenNguoiDaiDien === "" ||
      dataBusiness.tenNguoiDaiDien == null
    ) {
      tennguoidaidienErrors.tennguoidaidienRequired = MESSAGE_REQUIRE;
      isValid = false;
    } else if (dataBusiness.tenNguoiDaiDien.length > 255) {
      tennguoidaidienErrors.tennguoidaidienRequired = MESSAGE_GIOIHAN255_KYTU;
      isValid = false;
    }
    if (
      dataBusiness.tenDoanhNghiep === "" ||
      dataBusiness.tenDoanhNghiep == null
    ) {
      tenDoanhNghiepErrors.tenDoanhNghiepRequired = MESSAGE_REQUIRE;
      isValid = false;
    } else if (dataBusiness.tenDoanhNghiep.length > 255) {
      tenDoanhNghiepErrors.tenDoanhNghiepRequired = MESSAGE_GIOIHAN255_KYTU;
      isValid = false;
    }
    if (dataBusiness.diaChi.length > 255) {
      diaChiErrors.diaChiRequired = MESSAGE_GIOIHAN255_KYTU;
      isValid = false;
    }

    /*if (
      dataBusiness.topicIn === "" ||
      dataBusiness.topicIn == null
    ) {
      topicInErrors.topicInRequired = MESSAGE_REQUIRE;
      isValid = false;
    } else if (dataBusiness.topicIn.length > 255) {
      topicInErrors.topicInRequired = MESSAGE_GIOIHAN255_KYTU;
      isValid = false;
    }
    if (
      dataBusiness.topicOut === "" ||
      dataBusiness.topicOut == null
    ) {
      topicOutErrors.topicOutRequired = MESSAGE_REQUIRE;
      isValid = false;
    } else if (dataBusiness.topicOut.length > 255) {
      topicOutErrors.topicOutRequired = MESSAGE_GIOIHAN255_KYTU;
      isValid = false;
    }*/
    setTendoanhnghiepErrors(tenDoanhNghiepErrors);
    setThudientuErrors(thudientuErrors);
    setSodienthoaiErrors(sodienthoaiErrors);
    setTennguoidaidienErrors(tennguoidaidienErrors);
    setDiaChiErros(diaChiErrors);
    setTopicInErrors(topicInErrors);
    setTopicOutErrors(topicOutErrors);
    return isValid;
  };

  const handleSaveOrEdit = async () => {
    showLoader();
    let isValid = formValidation();
    console.log(isValid);
    if (isValid) {
      if (typeAd === "1") {
        console.log(dataBusiness);
       
        const result = await businessManagementService.saveBusinessManagement(
          dataBusiness
        );
        if (result && result.status === 1000) {
          setTimeout(fetDataBusiness, 500); // đợi 0.5s sau mới gọi hàm fetData()
          onHideDialog();
          let message = result.message;
          notification.notifySuccess(message);
        } else {
          let message = result.message;
          notification.notifyError(message);
        }
      } else if (typeAd === "2") {
        // function update
        const result = await businessManagementService.editBusinessManagement(
          dataBusiness
        );
        if (result && result.status === 1000) {
          setTimeout(fetDataBusiness, 500); // đợi 0.5s sau mới gọi hàm fetData()
          onHideDialog();
          let message = result.message;
          notification.notifySuccess(message);
        } else {
          let message = result.message;
          notification.notifyError(message);
        }
      }
    }

    hideLoader();
  };
const hiddenPopUp =()=> {
    onHideDialog()
    setTendoanhnghiepErrors('');
    setThudientuErrors('');
    setSodienthoaiErrors('');
    setTennguoidaidienErrors('');
    setDiaChiErros('');
}
  const renderHeader = () => {
    if (typeAd === "1") {
      return <p>Tạo mới doanh nghiệp</p>;
    } else if (typeAd === "2") {
      return <p>Sửa doanh nghiệp</p>;
    } else if (typeAd === "3") {
      return <p>Xem thông tin doanh nghiệp</p>;
    }
  };
  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Hủy"
          icon="pi pi-times"
          onClick={hiddenPopUp}
          className="p-button-text p-button-danger"
        />
        <Button
          label="Xác nhận"
          icon="pi pi-check"
          className="p-button-success"
          onClick={handleSaveOrEdit}
        />
      </div>
    );
  };
  return (
    <div className="popup-create-busines">
      {loader}
      <Dialog
        header={renderHeader}
        visible={visible}
        onHide={hiddenPopUp}
        style={{ width: "70vw" }}
        footer={typeAd === "3" ? "" : renderFooter}
      >
        <div className="p-fluid p-formgrid p-grid form-user">
          <div className="p-field p-col user-col-left">
            <label htmlFor="mst">Mã số thuế</label>
            <InputText
              id="mst"
              placeholder="Mã số thuế"
              value={dataBusiness.mst || ""}
              name="mst"
              onChange={(e) => updateField(e.target.value, "mst")}
            />
          </div>
          <div className="p-field p-col user-col-right">
            <label htmlFor="tenDoanhNghiep">Tên doanh nghiệp</label>
            <InputText
              id="tenDoanhNghiep"
              placeholder="Tên doanh nghiệp"
              value={dataBusiness.tenDoanhNghiep || ""}
              name="hoTen"
              onChange={(e) => updateField(e.target.value, "tenDoanhNghiep")}
            />
            {Object.keys(tendoanhnghiepErrors).map((keyIndex, key) => {
              return (
                <span className="p-error" key={key}>
                  {tendoanhnghiepErrors[keyIndex]}
                </span>
              );
            })}
          </div>
        </div>

        <div className="p-fluid p-formgrid p-grid form-user">
          <div className="p-field p-col user-col-left">
            <label htmlFor="thuDienTu">Thư điện tử</label>
            <InputText
              id="thuDienTu"
              placeholder="Thư điện tử"
              value={dataBusiness.thuDienTu || ""}
              onChange={(e) => updateField(e.target.value, "thuDienTu")}
              name="thuDienTu"
            />
            {Object.keys(thudientuErrors).map((keyIndex, key) => {
              return (
                <span className="p-error" key={key}>
                  {thudientuErrors[keyIndex]}
                </span>
              );
            })}
          </div>
          <div className="p-field p-col user-col-right">
            <label htmlFor="soDienThoai">Số điện thoại</label>
            <InputText
              id="soDienThoai"
              placeholder="Số điện thoại"
              name="soDienThoai"
              onChange={(e) => updateField(e.target.value, "soDienThoai")}
              value={dataBusiness.soDienThoai || ""}
            />
            {Object.keys(sodienthoaiErrors).map((keyIndex, key) => {
              return (
                <span className="p-error" key={key}>
                  {sodienthoaiErrors[keyIndex]}
                </span>
              );
            })}
          </div>
        </div>

        <div className="p-fluid p-formgrid p-grid form-user">
          <div className="p-field p-col user-col-left">
            <label htmlFor="diaChi">Địa chỉ</label>
            <InputText
              id="diaChi"
              placeholder="Địa chỉ"
              name="diaChi"
              value={dataBusiness.diaChi || ""}
              onChange={(e) => updateField(e.target.value, "diaChi")}
            />
            {Object.keys(diaChiErros).map((keyIndex, key) => {
              return (
                <span className="p-error" key={key}>
                  {diaChiErros[keyIndex]}
                </span>
              );
            })}
          </div>
          <div className="p-field p-col user-col-right">
            <label htmlFor="tenNguoiDaiDien">Tên người đại diện</label>
            <InputText
              id="tenNguoiDaiDien"
              placeholder="Tên người đại diện"
              name="tenNguoiDaiDien"
              value={dataBusiness.tenNguoiDaiDien || ""}
              onChange={(e) => updateField(e.target.value, "tenNguoiDaiDien")}
            />
            {Object.keys(tennguoidaidienErrors).map((keyIndex, key) => {
              return (
                <span className="p-error" key={key}>
                  {tennguoidaidienErrors[keyIndex]}
                </span>
              );
            })}
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid" >
            <div className="p-field p-col">
              <label htmlFor="loaiTruyenNhan">
                Hình thức <span className="item-required">*</span> :
              </label>
              <div
                className="p-formgroup-inline p-mr-2 p-as-center"
                style={{ marginTop: "10px",display:'flex',flexDirection:'row' }}
              >
                <div className="p-field-radiobutton">
                  <RadioButton
                   
                    inputId="restApi"
                    name="restApi"
                    value={1}
                    checked={phuongthuctruyennhan === 1}
                    onChange={(e) => updateFieldradio(e.target.value, "phuongThucGui")}
                  />
                  <label htmlFor="restApi"  style={{marginRight:'10px'}}>API</label>
                </div>
                <div className="p-field-radiobutton">
                  <RadioButton
                    inputId="queueMessage"
                    name="queueMessage"
                    value={0}
                    checked={phuongthuctruyennhan === 0}
                    onChange={(e) => updateFieldradio(e.target.value, "phuongThucGui")}
                  />
                  <label htmlFor="queueMessage">Queue</label>
                </div>
              </div>
            </div>
          </div>

        {phuongthuctruyennhan === 0 ? (
          <>
          <div className="p-fluid p-formgrid p-grid form-user">
          <div className="p-field p-col user-col-left">
            <label htmlFor="topicIn">Topic In</label>
            <InputText
              id="topicIn"
              placeholder="Topic nhận thông điệp"
              name="topicIn"
              value={dataBusiness.topicIn || ""}
              onChange={(e) => updateField(e.target.value, "topicIn")}
            />
            {Object.keys(topicInErrors).map((keyIndex, key) => {
              return (
                <span className="p-error" key={key}>
                  {topicInErrors[keyIndex]}
                </span>
              );
            })}
          </div>
          <div className="p-field p-col user-col-right">
            <label htmlFor="topicOut">Topic Out</label>
            <InputText
              id="topicOut"
              placeholder="Topic nhận thông điệp từ Tvan"
              name="topicOut"
              value={dataBusiness.topicOut || ""}
              onChange={(e) => updateField(e.target.value, "topicOut")}
            />
            {Object.keys(topicOutErrors).map((keyIndex, key) => {
              return (
                <span className="p-error" key={key}>
                  {topicOutErrors[keyIndex]}
                </span>
              );
            })}
          </div>
        </div>

        <div className="p-fluid p-formgrid p-grid form-user">
          <div className="p-field p-col user-col-left">
            <label htmlFor="topicUsername">Topic Username</label>
            <InputText
              id="topicUsername"
              placeholder="User topic"
              name="topicUsername"
              value={dataBusiness.topicUsername || ""}
              onChange={(e) => updateField(e.target.value, "topicUsername")}
            />
          </div>
          <div className="p-field p-col user-col-right">
            <label htmlFor="topicPassword">Topic Password</label>
            <InputText
              id="topicPassword"
              placeholder="Topic password"
              name="topicPassword"
              value={dataBusiness.topicPassword || ""}
              onChange={(e) => updateField(e.target.value, "topicPassword")}
            />
            
          </div>
        </div>
          </>
        ):(
           <div></div>
        )}

        

        <div className="p-fluid p-formgrid p-grid form-user">
          <div className="p-field p-col-12 user-col-mota">
            <label htmlFor="address">Mô tả</label>
            <InputTextarea
              type="text"
              rows={3}
              cols={5}
              value={dataBusiness.moTa || ""}
              onChange={(e) => updateField(e.target.value, "moTa")}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PopupCreateNewBusiness;
